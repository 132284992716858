<template>
  <div>
    <custom-data-table
      ref="datatable-schedule"
      :fields="fields"
      :filters="filters"
      :query="toScheduleQuery"
      query-node="schedules"
      query-context="Maintenance"
      :fixed-filters="fixed_filters"
      @vuetable:row-clicked="rowClicked"
    >
      <template slot="driverName" slot-scope="props">
        {{ props.rowData.driver.name || $t('maintenance.labels.driverName') }}
      </template>
      <template slot="car" slot-scope="props">
        {{ props.rowData.car.license_plate || $t('maintenance.labels.carLicensePlate') }}
      </template>
      <template slot="start_date" slot-scope="props">
        {{ props.rowData.scheduled_at | moment('DD/MM/YYYY HH:mm') }}
      </template>
      <template slot="garage" slot-scope="props">
        <span v-if="props.rowData.garage && props.rowData.garage.name">
          {{ props.rowData.garage.name }}
        </span>
        <span v-else class="no-garage">
          Sem oficina
        </span>
      </template>
      <template slot="odometer" slot-scope="props">
        {{ props.rowData | schedule_odometer_status }}
      </template>
      <template slot="status" slot-scope="props">
        <big-badge :variant="props.rowData.status | schedule_status_to_bootstrap_variant">
          {{ props.rowData.status | schedule_status_to_human }}
        </big-badge>
      </template>
    </custom-data-table>
    <modal-update-schedule :schedule="schedule" @updated="onRefreshDatatable" />
  </div>
</template>
<style scoped>
.no-garage {
  font-weight: bold;
  color: red;
}
</style>

<script>
import ModalUpdateSchedule from '@components/modals/update-schedule';
import CustomDataTable from '@components/CustomDataTable';
import TO_SCHEDULE_LIST from '@graphql/schedules/queries/list.gql';
import BigBadge from '@components/shared/big-badge';

export default {
  name: 'ScheduledList',
  components: {
    CustomDataTable,
    ModalUpdateSchedule,
    BigBadge,
  },
  data() {
    return {
      schedule: {},
      toScheduleQuery: TO_SCHEDULE_LIST,
      fields: [
        {
          name: 'driverName',
          title: this.$t('maintenance.fields.driverName'),
          sortField: 'driverName'
        },
        {
          name: 'car',
          title: this.$t('maintenance.fields.car'),
          sortField: 'car'
        },
        {
          name: 'start_date',
          title: this.$t('maintenance.fields.scheduledDate')
        },
        {
          name: 'garage',
          title: this.$t('maintenance.fields.garage'),
        },
        {
          name: 'odometer',
          title: this.$t('maintenance.fields.odometer'),
        },
        {
          name: 'status',
          title: this.$t('maintenance.fields.status'),
        },
      ],
    };
  },
  computed: {
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: false,
            placeholder: this.$t('maintenance.labels.searchPlaceholder'),
          },
          valueParserType: String,
          graphqlQuery(value) {
            return {
              search: {
                field: 'driver_name',
                value,
              },
            };
          },
        },
      };
    },
    fixed_filters() {
      return {
        status: 'SCHEDULED',
      };
    },
  },
  methods: {
    onRefreshDatatable() {
      this.$refs['datatable-schedule'].$_refresh();
    },

    rowClicked({ data }) {
      this.schedule = data;

      this.$root.$emit('bv::show::modal', 'modal-update-schedule');
    },
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("custom-data-table", {
        ref: "datatable-schedule",
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.toScheduleQuery,
          "query-node": "schedules",
          "query-context": "Maintenance",
          "fixed-filters": _vm.fixed_filters,
        },
        on: { "vuetable:row-clicked": _vm.rowClicked },
        scopedSlots: _vm._u([
          {
            key: "driverName",
            fn: function (props) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      props.rowData.driver.name ||
                        _vm.$t("maintenance.labels.driverName")
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "car",
            fn: function (props) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      props.rowData.car.license_plate ||
                        _vm.$t("maintenance.labels.carLicensePlate")
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "start_date",
            fn: function (props) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("moment")(
                        props.rowData.scheduled_at,
                        "DD/MM/YYYY HH:mm"
                      )
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "garage",
            fn: function (props) {
              return [
                props.rowData.garage && props.rowData.garage.name
                  ? _c("span", [
                      _vm._v(" " + _vm._s(props.rowData.garage.name) + " "),
                    ])
                  : _c("span", { staticClass: "no-garage" }, [
                      _vm._v(" Sem oficina "),
                    ]),
              ]
            },
          },
          {
            key: "odometer",
            fn: function (props) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("schedule_odometer_status")(props.rowData)) +
                    " "
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (props) {
              return [
                _c(
                  "big-badge",
                  {
                    attrs: {
                      variant: _vm._f("schedule_status_to_bootstrap_variant")(
                        props.rowData.status
                      ),
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("schedule_status_to_human")(
                            props.rowData.status
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("modal-update-schedule", {
        attrs: { schedule: _vm.schedule },
        on: { updated: _vm.onRefreshDatatable },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <kovi-page-title :text="$t('maintenance.titles.scheduledView')" />
    <b-row>
      <b-col sm="12">
        <scheduled-list />
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
  import Layout from '@layouts/main';
  import KoviPageTitle from '@components/shared/kovi-page-title';
  import ScheduledList from '@components/schedule/scheduled-list';

  export default {
    name: 'MaintenanceScheduled',
    components: {
      Layout,
      KoviPageTitle,
      ScheduledList,
    },
  };

</script>
